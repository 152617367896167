(() => {
    const isProduction = process.env.NODE_ENV === 'production';
  
    const initializeGtag = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      window.gtag = gtag; 
  
      gtag('js', new Date());
  
      if (isProduction) {
        // Configuración para producción
        gtag('config', 'G-SBHLD0HG88');
      } else {
        // Configuración para desarrollo
        gtag('config', 'G-SBHLD0HG88', {
          'cookie_domain': 'none'
        });
      }
    };
  
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-SBHLD0HG88';
    script.onload = initializeGtag;  // Inicializar gtag una vez que el script se haya cargado
    document.head.appendChild(script);
  })();
  